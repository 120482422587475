// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var boxContainer = "x_bK";
export var boxInfo = "x_bL";
export var boxNotice = "x_bM";
export var boxDefinition = "x_bN";
export var boxSnippet = "x_bP";
export var boxIcon = "x_bQ";